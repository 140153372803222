import { writable } from "svelte/store";
function createUserStore() {
    const { subscribe, set } = writable(null);
    return {
        subscribe,
        login: set,
        logout: () => set(null),
    };
}
export const user = createUserStore();
