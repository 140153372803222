import { writable } from "svelte/store";
import { getSelectedCollection, storeSelectedCollection } from "../utils/store";
const initialCollection = getSelectedCollection();
function createSelectedCollection() {
    const { subscribe, set } = writable(initialCollection || { id: "", name: "" });
    return {
        subscribe,
        select: (collection) => {
            storeSelectedCollection(collection);
            set(collection);
        },
        validate: (collections) => {
            const { id } = getSelectedCollection();
            if (id) {
                set(collections.find(collection => collection.id === id) || {
                    id: "",
                    name: "",
                });
            }
        },
    };
}
export const selectedCollection = createSelectedCollection();
