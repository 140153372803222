import { db } from "../../config/firebase";
import { collectionData } from "rxfire/firestore";
import { deleteItemsByCollectionId } from "./shopping";
const DATABASE_NAME = "collection";
export async function addCollection(name) {
    return db.collection(DATABASE_NAME).add({ name });
}
export async function deleteCollection(id) {
    return db
        .collection(DATABASE_NAME)
        .doc(id)
        .delete()
        .then(() => {
        deleteItemsByCollectionId(id);
    });
}
export function updateCollectionName(newName, id) {
    return db.collection(DATABASE_NAME).doc(id).update({ name: newName });
}
export function getCollections() {
    const query = db.collection(DATABASE_NAME);
    return collectionData(query, "id");
}
