import { db } from "../../config/firebase";
import { collectionData } from "rxfire/firestore";
const DATABASE_NAME = "shopping-list";
const ORDER_BY = "creationDate";
export function addItem(name, collectionId) {
    return db.collection(DATABASE_NAME).add({
        name,
        isBought: false,
        collectionId,
        creationDate: Date.now(),
    });
}
export function addBatch(items, collectionId) {
    var batch = db.batch();
    items.forEach(name => {
        const docRef = db.collection(DATABASE_NAME).doc();
        batch.set(docRef, {
            name,
            isBought: false,
            collectionId,
            creationDate: Date.now(),
        });
    });
    return batch.commit();
}
export function updateName(newName, id) {
    return db.collection(DATABASE_NAME).doc(id).update({ name: newName });
}
export function removeItem(id) {
    return db.collection(DATABASE_NAME).doc(id).delete();
}
export function updateStatus(newStatus, id) {
    return db.collection(DATABASE_NAME).doc(id).update({ isBought: newStatus });
}
export function deleteItemsByCollectionId(collectionId) {
    return db
        .collection(DATABASE_NAME)
        .where("collectionId", "==", collectionId)
        .get()
        .then(items => {
        const batch = db.batch();
        items.forEach(item => {
            batch.delete(item.ref);
        });
        return batch.commit();
    });
}
export function getItems(collectionId) {
    const query = db
        .collection(DATABASE_NAME)
        .where("collectionId", "==", collectionId)
        .orderBy(ORDER_BY);
    return collectionData(query, "id");
}
